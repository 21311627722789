export const VALIDATION_MESSAGES = {
  LOGIN: {
    USER_NAME: {
      REQUIRED: "User name is required.",
    },
    PASSWORD: {
      REQUIRED: "Password is required.",
      MIN_LENGTH: "Your password should be more than 8 characters.",
    },
  },
  PROFILE: {
    FIRST_NAME: {
      REQUIRED: "First name is required.",
    },
    LAST_NAME: {
      REQUIRED: "Last name is required.",
    },
    EMAIL: {
      REQUIRED: "Email is required.",
      INVALID: "Invalid email address.",
    },
    PASSWORD: {
      PASSWORD: "Password is required.",
      CONFIRM_PASSWORD: "Confirm password is required.",
      INVALID_CONFIRM_PASSWORD: "Confirm password must be same as password.",
      NEW_PASSWORD: "New password is required.",
      INVALID_CONFIRM_NEW_PASSWORD: "Confirm password must be same as new password.",
      CHANGE: {
        REQUIRED_CURRENT_PASSWORD: "Current password is required.",
        REQUIRED_NEW_PASSWORD: "New password is required.",
      },
      INVALID: {
        MIN_LENGTH: "Your password should be more than 6 characters.",
        NUMBER: "Your password should contain at least one number.",
        UPPERCASE: "Your password should contain at least one uppercase letter.",
        LOWERCASE: "Your password should contain at least one lowercase letter.",
        SPECIAL_CHARACTER: "Your password should contain at least one special character.",
      },
    },
    OTP: {
      REQUIRED: "OTP is required.",
      INVALID: "Invalid OTP.",
    },
  },

  TENANTS: {
    FIRST_NAME: {
      REQUIRED: "First name is required.",
    },
    LAST_NAME: {
      REQUIRED: "Last name is required.",
    },
    EMAIL: {
      REQUIRED: "Email is required.",
      INVALID: "Invalid email address.",
    },
    ORGANIZATION_TYPE: {
      REQUIRED: "You must enter an organization type.",
    },
    AREA: {
      REQUIRED: "You must enter an area.",
    },
    LOCATION: {
      REQUIRED: "You must enter a location.",
    },
    COMPANY_EMAIL: {
      REQUIRED: "You nust enter your company's email address.",
      INVALID: "Invalid email address.",
    },
    COMPANY_PHONE: {
      REQUIRED: "You nust enter your company's phone number.",
      INVALID: "Invalid phone number.",
    },
    WEBSITE: {
      REQUIRED: "You nust enter your website",
      INVALID: "Invalid website.",
    },
  },

  SERVICE_TYPES: {
    NAME: {
      REQUIRED: "Name is required.",
    },
    DESCRIPTION: {
      REQUIRED: "Description is required.",
    },
  },

  ROLES: {
    NAME: {
      REQUIRED: "Please enter role.",
    },
    PERMISSIONS: {
      REQUIRED: "Permissions are required.",
    },
  },

  SERVICES: {
    NAME: {
      REQUIRED: "Please enter service name.",
    },
    TYPE: {
      REQUIRED: "Please select service type.",
    },
    DURATION: {
      REQUIRED: "Please enter service duration.",
    },
    UNIT: {
      REQUIRED: "Please select service duration unit.",
    },
    PRICE: {
      REQUIRED: "Please enter service price.",
    },
    DISCOUNT: {
      REQUIRED: "Please enter service discount.",
    },
  },

  FORM_BUILDER: {
    FORM_NAME_REQUIRED: "Form name is required",
    SERVICE_REQUIRED: "Service is required",
  },

  PLANS: {
    NAME: {
      REQUIRED: "Please enter plan name.",
    },
    TYPE: {
      REQUIRED: "Please select plan type.",
    },
    DURATION: {
      REQUIRED: "Please enter plan duration.",
    },
    UNIT: {
      REQUIRED: "Please select plan duration unit.",
    },
    PRICE: {
      REQUIRED: "Please enter plan price.",
    },
    DESCRIPTION: {
      REQUIRED: "Please enter description."
    }
  },
};

export const STATUS_BUTTON_VALUE = [
  { id: 0, label: "Inactive", color: "error", background: "rgba(244, 67, 52, 0.80)" },
  { id: 1, label: "Active", color: "success", background: "rgba(99, 185, 103, 0.80)" },
  { id: 2, label: "Draft", color: "light", background: "#F3F4F6" },
  { id: 3, label: "Pending", color: "warning", background: "rgba(251, 140, 0, 0.80)" },
  { id: 4, label: "In Progress", color: "warning", background: "rgba(251, 140, 0, 0.80)" },
  { id: 5, label: "On Hold", color: "warning", background: "rgba(251, 140, 0, 0.80)" },
  { id: 6, label: "Ended", color: "light", background: "#F3F4F6" },
  { id: 7, label: "Retracted", color: "info", background: "rgba(12, 142, 189, 0.20)" },
];

export const PROGRESS_STATUS_BUTTON_VALUE = [
  { id: 0, label: "Rejected", color: "error", background: "rgba(244, 67, 52, 0.80)" },
  { id: 1, label: "Pending", color: "light", background: "#F3F4F6" },
  { id: 2, label: "Approved", color: "success", background: "rgba(99, 185, 103, 0.80)" },
  { id: 3, label: "Pending", color: "light", background: "#F3F4F6" },

];

export const PROGRESS_TYPE_STATUS_VALUE = [
  { id: 0, label: "Inactive" },
  { id: 1, label: "Active" },
  { id: 2, label: "Draft" },
  { id: 3, label: "Locked" },
];

export const FILTER_STATUS_VALUE = [
  { id: 0, label: "Inactive", color: "error", background: "rgba(244, 67, 52, 0.80)" },
  { id: 1, label: "Active", color: "success", background: "rgba(99, 185, 103, 0.80)" },
];

export const ENTRIES_PER_PAGE_OPTIONS = ["10", "25", "50", "100"];

export const PERMISSIONS = {
  READ_ACCESS: "read_access",
  WRITE_ACCESS: "write_access",
  DELETE_ACCESS: "delete_access",
};

export const DURATION_UNIT = {
  1: "Day",
  2: "Week",
  3: "Month",
  4: "Year",
};

export const SERVICE_TYPES_ACTIONS = {
  INACTIVE: "inactive",
  DELETE: "delete",
};

export const TABLE_FILTERS = {
  TENANTS: "tenants",
  ROLES: "roles",
  SERVICE_TYPES: "serviceTypes",
  SERVICES: "services",
  FORM_BUILDER: "formBuilder",
  PLANS: "plans",
  EMAIL_TEMPLATES: "emailTemplates",
  SERVICE_TYPES_REQUESTS: "serviceTypesRequests",
  COUPONS: "coupons",
};

export const LOCAL_STORAGE = {
  FILTERS: "filters",
  SORT_BY: "sortBy",
};

export const DRAG_AREA_TEXT =
  "Select an item or drag and drop it into this area to start building your form!";

export const CURRENCY = {
  usd: "$",
};

// 1 = regular plan, 2 = addons
export const PLAN_TYPES = {
  1: "Regular Plan",
  2: "Add-on",
};

export const COUPON_TYPES = {
  1: "Flat",
  2: "Percentage",
};

export const COUPON_APPLICABLE_FOR_TYPES = {
  1:'Both',
  2:'Subscription',
  3:'Add-ons'
}
export const PLAN_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Regular Plan",
  },
  {
    value: 2,
    label: "Add-on",
  },
];

export const COUPON_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Flat",
  },
  {
    value: 2,
    label: "Percentage",
  },
];

export const COUPON_APPLICABLE_FOR_OPTIONS = [
  {
    value: 1,
    label: "Both",
  },
  {
    value: 2,
    label: "Subscription",
  },
  {
    value: 3,
    label: "Add-ons",
  },
];

//  "1 = yearly, 2 = monthly"
export const PLAN_DURATION_TYPE = {
  1: "Yearly",
  2: "Monthly",
  3: "One-off"
};

export const PLAN_DURATION_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Yearly",
  },
  {
    value: 2,
    label: "Monthly",
  },
];

export const PLAN_DURATION_SEARCH_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Yearly",
  },
  {
    value: 2,
    label: "Monthly",
  },
  {
    value: 3,
    label: "One-off",
  },
];

// array to store the list of all the fields that include 'time' in them but are not of type 'time'
export const NOT_TIME_FIELDS = ['Appointment Timeliness'] 