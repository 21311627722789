// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid, IconButton } from "@mui/material";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { Edit } from "@mui/icons-material";

import { TABLE_FILTERS } from "utils/constants";

import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { getEmailTemplates } from "lib/Api/EmailTemplates";
import { getSubscriptionsListingApi } from "lib/Api/Dashboard";
import moment from "moment";

function SubscriptionListing() {
  const navigate = useNavigate();

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Tenant Name",
      accessor: "tenant_name",
      sort: false,
      Cell: ({ value, row }) => (
        <DefaultCell value={`${row.original.user.first_name} ${row.original.user.last_name}`} />
      ),
    },
    {
      Header: "Tenant Email",
      accessor: "tenant_email",
      // filterInputType: "text",
      sort: false,
      Cell: ({ value, row }) => <DefaultCell value={row.original.user.email} />,
    },
    {
      Header: "Plan",
      accessor: "plan",
      sort: false,
      Cell: ({ value }) => <DefaultCell value={value.name} />,
    },
    {
      Header: "Price",
      accessor: "price",
      sort: false,
      Cell: ({ value, row }) => (
        <DefaultCell
          value={`$${row.original.plan.price}/${
            row.original.plan.plan_duration_type ? "year" : "month"
          }`}
        />
      ),
    },
    {
      Header: "Plan End Date",
      accessor: "end_date",
      sort: false,
      Cell: ({ value }) => <DefaultCell value={moment(value).format("MM-DD-YYYY")} />,
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.EMAIL_TEMPLATES) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.EMAIL_TEMPLATES) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.EMAIL_TEMPLATES, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.EMAIL_TEMPLATES, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.EMAIL_TEMPLATES, sortObject);
  };

  const getSubscriptionListingData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status" || filter.id == "service_type_id") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getSubscriptionsListingApi(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getSubscriptionListingData): ", error);
    }
  };

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getSubscriptionListingData();
  }, [page, pageSize, filters, sortBy]);

  return (
    <MDBox pt={3} pb={3}>
      {!isLoading && (
        <DataTable
          isLoading={isLoading}
          table={tableData}
          pageSize={pageSize}
          currentPage={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          filters={filters}
          handleFiltersChange={handleFiltersChange}
          sorting={sortBy}
          handleSortByChange={handleSortByChange}
          getData={getSubscriptionListingData}
          handleClearAllFilters={handleClearAllFilters}
          loading={isLoading}
          entriesPerPage={false}
          doNotFilter={true} // passing this flag for not showing clear all button
        />
      )}
    </MDBox>
  );
}

export default SubscriptionListing;
