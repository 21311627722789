// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDModal from "components/MDModal";

// Material Dashboard 2 PRO React examples
import LocalDataTable from "examples/Tables/LocalDataTable";
// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "context/ModalCtx";

import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import { Add, Visibility } from "@mui/icons-material";
import { TABLE_FILTERS } from "utils/constants";
import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import DefaultCell from "examples/Tables/components/DefaultCell";
import moment from "moment";
import { LoginFormContext } from "context/loginFormCtx";
import { getSubscriptions } from "lib/Api/Subscriptions";

function Subscriptions() {
  const { formIsEdit } = useContext(ModalContext);

  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);

  const dataTableData = {
    columns: [
      {
        Header: "Email",
        accessor: "email",
        filterInputType: "text",
        sort: true,
        Cell: ({ value, row }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Plan",
        accessor: "plan",
        filterInputType: "text",
        sort: true,
        Cell: ({ value, row }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Plan Start Date",
        accessor: "current_period_start",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Plan End Date",
        accessor: "current_period_end",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      // incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
      {
        Header: "Status",
        accessor: "status",
        filterInputType: "select",
        sort: true,
        filterOptions: [
          { value: "Incomplete", label: "Incomplete" },
          {
            value: "Incomplete Expired",
            label: "Incomplete Expired",
          },
          { value: "Trialing", label: "Trialing" },
          { value: "Active", label: "Active" },
          { value: "Past Due", label: "Past Due" },
          { value: "Canceled", label: "Canceled" },
          { value: "Unpaid", label: "Unpaid" },
        ],
        Cell: ({ value }) => <DefaultCell value={value} textTransform="capitalize" />,
      },
      {
        Header: "Cancel Date",
        accessor: "canceled_at",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Action",
        width: "3%",
        sort: false,
        Cell: ({ value, row }) => {
          return (
            <MDBox>
              <Tooltip title="View Subscriptions" placement="top">
                <IconButton
                  onClick={() => {
                    navigate(`/subscriptions/${row?.original?.id}`, { state: { isEditMode: false } });
                  }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],

    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);

  const [tableData, setTableData] = useState({
    columns: dataTableData.columns,
    rows: [],
  });

  const getSubscriptionsData = async () => {
    try {
      setIsLoading(true);
      const response = await getSubscriptions();
      if (response?.status === 200) {
        const responseData = response?.data?.data;
        setSubscriptions(responseData?.data);

        const tempRows = responseData?.data.map((subscription) => {
          return {
            id: subscription.id,
            email: subscription.customer.email,
            plan: subscription.plan.id,
            current_period_start: moment(subscription.current_period_start * 1000).format(
              "MM/DD/YYYY"
            ),
            current_period_end: moment(subscription.current_period_end * 1000).format("MM/DD/YYYY"),
            status: subscription.status,
            canceled_at: subscription.canceled_at
              ? moment(subscription.canceled_at * 1000).format("MM/DD/YYYY")
              : "-",
          };
        });

        setTableData({
          ...tableData,
          rows: tempRows,
        });
      } else {
        console.log("response (getSubscriptionsData): ", response);
        setNotification({
          open: true,
          severity: "error",
          message: "Something went wrong while fetching the subscriptions. Please try again.",
        });
        navigate("/dashboard");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error (getSubscriptionsData): ", error);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    getSubscriptionsData();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Subscriptions
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the subscriptions.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <LocalDataTable isLoading={isLoading} table={tableData} isCSVExport />
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default Subscriptions;
