// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Visibility } from "@mui/icons-material";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
// import { ModalContext } from "context/ModalCtx";

import { getPlans, deletePlan, updatePlan } from "lib/Api/Plans";

import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { LoginFormContext } from "context/loginFormCtx";
import { STATUS_BUTTON_VALUE, DURATION_UNIT } from "utils/constants";
import { Delete, Edit } from "@mui/icons-material";

import moment from "moment";
import { TABLE_FILTERS } from "utils/constants";

import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { PLAN_TYPES } from "utils/constants";
import { PLAN_DURATION_TYPE } from "utils/constants";
import { PLAN_TYPE_OPTIONS } from "utils/constants";
import { PLAN_DURATION_SEARCH_TYPE_OPTIONS } from "utils/constants";

function Plans() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletePlan(id);

        if (response?.status === 200) {
          getPlansData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this Plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updatePlan(id, { status: status ? 0 : 1 });

        if (response?.status === 200) {
          getPlansData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Name",
      accessor: "name",
      filterInputType: "text",
      sort: true,
      Cell: ({ value, row }) => {
        return (
          <>
            <Link to={`/plans/${row.values.id}`}>
              <DefaultCell value={value} />
            </Link>
          </>
        );
      },
    },
    // 1 = regular plan, 2 = addons
    {
      Header: "Plan Type",
      accessor: "plan_type",
      filterInputType: "select",
      sort: true,
      filterOptions: PLAN_TYPE_OPTIONS,
      Cell: ({ value, row }) => {
        return <DefaultCell value={PLAN_TYPES[value] || "N/A"} />;
      },
    },
    {
      Header: "Duration",
      accessor: "duration",
      filterInputType: "number",
      sort: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={value} />;
      },
    },
    //  "1 = yearly, 2 = monthly"
    {
      Header: "PLan Duration Type",
      accessor: "plan_duration_type",
      filterInputType: "select",
      sort: true,
      filterOptions: PLAN_DURATION_SEARCH_TYPE_OPTIONS,
      Cell: ({ value, row }) => {
        return <DefaultCell value={PLAN_DURATION_TYPE[value] || "N/A"} />;
      },
    },
    {
      Header: "Final Price",
      accessor: "final_price",
      filterInputType: "number",
      sort: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={value} />;
      },
    },
    {
      Header: "Per User Charges",
      accessor: "per_user_charges",
      filterInputType: "number",
      sort: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={value} />;
      },
    },
    {
      Header: "status",
      accessor: "status",
      filterInputType: "select",
      sort: true,
      filterOptions: [
        { label: "Inactive", value: 0 },
        { label: "Active", value: 1 },
      ],
      Cell: ({ value, row }) => (
        <MDButton
          color={STATUS_BUTTON_VALUE[value].color}
          sx={{
            background: `${STATUS_BUTTON_VALUE[value].background}`,
          }}
          size="small"
          circular={true}
          onClick={() => {
            handleStatusChange(row.original.id, value);
          }}
        >
          {STATUS_BUTTON_VALUE[value].label}
        </MDButton>
      ),
    },
    {
      Header: "Created At",
      accessor: "created_at",
      filterInputType: "date",
      disableFuture: true,
      sort: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
    },
    {
      Header: "Action",
      accessor: "actions",
      width: "3%",
      sort: false,
      Cell: ({ value, row }) => {
        return (
          <MDBox>
            <Tooltip title="View Plan" placement="top">
              <IconButton
                onClick={() => {
                  navigate(`/plans/${row?.original?.id}`, { state: { isEditMode: false } });
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Plan" placement="top">
              <IconButton
                onClick={() => {
                  navigate(`/plans/${row.original.id}/edit`);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Plan" placement="top">
              <IconButton
                onClick={() => {
                  handleDelete(row.original.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </MDBox>
        );
      },
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.PLANS) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.PLANS) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.PLANS, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.PLANS, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.PLANS, sortObject);
  };

  const getPlansData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (
          filter.id === "status" ||
          filter.id === "type" ||
          filter.id === "plan_type" ||
          filter.id === "plan_duration_type"
        ) {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getPlans(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getPlansData): ", error);
    }
  };

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getPlansData();
  }, [page, pageSize, filters, sortBy]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Plans
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the plans.
                </MDTypography>
              </Grid>
              <Grid item>
                <Link to="/plans/add-plan">
                  <Button variant="outlined" startIcon={<AddIcon />}>
                    Add New Plan
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </MDBox>
          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getPlansData}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default Plans;
