// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import LocalDataTable from "examples/Tables/LocalDataTable";
// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { useCallback, useContext, useEffect, useState } from "react";

import moment from "moment";
import { LoginFormContext } from "context/loginFormCtx";
import MDInput from "components/MDInput";
import { fetchMasterSettingsApi } from "lib/Api/MasterSettings";
import { useFormik, Form, Formik, useFormikContext } from "formik";
import { Cancel, CancelOutlined, Check, CheckCircleOutline, Save } from "@mui/icons-material";
import { updateMasterSettingsApi } from "lib/Api/MasterSettings";

const BTN_ACTIONS = {
  SAVE: "save",
  CANCEL: "cancel",
};

function MasterSettings() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const [masterSettingConfiguration, setMasterSettingConfiguration] = useState([]);
  const [changedValues, setChangedValues] = useState([]);

  // fetch master settings data from API
  const fetchMasterSetting = async () => {
    try {
      const response = await fetchMasterSettingsApi();

      if (response?.status === 200) {
        const data = response.data?.data.rows;
        setMasterSettingConfiguration(data);
      } else {
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: response?.data?.message || "Something went wrong",
          open: true,
        });
      }
    } catch (error) {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: error?.response?.data?.message || "Something went wrong",
        open: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {},
  });

  const { setFieldValue } = formik;

  const watchAllFields = formik.values;

  const handleSetValueInForm = () => {
    masterSettingConfiguration?.forEach((setting) =>
      setting?.masterSettings?.forEach((masterSetting) => {
        if (masterSetting?.name === "appointment_cancellation_charges") {
          setFieldValue("appointment_cancellation_charges", masterSetting?.value === "1");
        } else {
          setFieldValue(`${masterSetting?.name}`, masterSetting?.value);
        }
      })
    );
  };

  const handleValueChange = (fieldName) => {
    if (!changedValues?.includes(fieldName)) setChangedValues([...changedValues, fieldName]);
  };

  const handleActionOnSetting = async (action, fieldName, valueIfCancel, idForSave) => {
    if (action === BTN_ACTIONS.SAVE) {
      let data = {
        name: fieldName,
        value: watchAllFields?.[fieldName],
      };
      if (fieldName === "appointment_cancellation_charges") {
        data = {
          name: fieldName,
          value: watchAllFields?.[fieldName] ? "1" : "0",
        };
      }
      try {
        const response = await updateMasterSettingsApi(idForSave, data);
        if (response?.status === 200) {
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: "Master setting configuration updated successfully",
            open: true,
          });

          setChangedValues(changedValues?.filter((value) => value !== fieldName));
        } else {
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response?.data?.message || "Something went wrong",
            open: true,
          });
        }
      } catch (error) {
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error?.response?.data?.message || "Something went wrong",
          open: true,
        });
      }
    } else if (action === BTN_ACTIONS.CANCEL) {
      setFieldValue(fieldName, valueIfCancel);
      setChangedValues(changedValues?.filter((value) => value !== fieldName));
    }
  };

  useEffect(() => {
    fetchMasterSetting();
  }, []);

  useEffect(() => {
    if (masterSettingConfiguration?.length > 0) {
      handleSetValueInForm();
    }
  }, [masterSettingConfiguration]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Master Settings
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the master settings.
                </MDTypography>
              </Grid>
            </Grid>
            {
              // Render master setting configuration data
              masterSettingConfiguration.map((setting) => (
                <Paper key={setting.id} sx={{ mb: 2 }}>
                  {setting?.masterSettings?.length > 0 && (
                    <>
                      <Stack direction="row" alignItems="center" sx={{ p: 2 }}>
                        <Typography variant="h6">{setting?.name}</Typography>
                      </Stack>
                      <Stack direction="row" sx={{ p: 2 }}>
                        <form>
                          {/* <FormProvider methods={methods}> */}
                          {setting?.masterSettings?.map((masterSetting) => (
                            <Stack
                              direction="row"
                              rowGap={2}
                              sx={{
                                width: "50rem",
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                                mb: 2,
                              }}
                            >
                              {masterSetting?.name === "appointment_cancellation_charges" ? (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="appointment_cancellation_charges"
                                        labelPlacement="start"
                                        checked={masterSetting?.value === "1"}
                                        onChange={handleValueChange}
                                      />
                                    }
                                    label="appointment_cancellation_charges"
                                  />
                                  {changedValues?.includes("appointment_cancellation_charges") && (
                                    <Stack direction="row" columnGap={1}>
                                      <IconButton
                                        color="success"
                                        onClick={() => {
                                          handleActionOnSetting(
                                            BTN_ACTIONS.SAVE,
                                            "appointment_cancellation_charges",
                                            null,
                                            masterSetting?.id
                                          );
                                        }}
                                      >
                                        <CheckCircleOutline />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        onClick={() => {
                                          handleActionOnSetting(
                                            BTN_ACTIONS.CANCEL,
                                            "appointment_cancellation_charges",
                                            masterSetting?.value
                                          );
                                        }}
                                      >
                                        <CancelOutlined />
                                      </IconButton>
                                    </Stack>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} sx={{ display:'flex', alignItems:'center'}}>
                                      <Typography>{masterSetting?.label}:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                      <MDInput
                                        name={masterSetting?.name}
                                        value={formik.values?.[masterSetting?.name]}
                                        onChange={(event) => {
                                          setFieldValue(masterSetting?.name, event.target.value);
                                          handleValueChange(masterSetting?.name);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>

                                  {changedValues?.includes(masterSetting?.name) && (
                                    <Stack direction="row" columnGap={1}>
                                      <IconButton
                                        color="success"
                                        onClick={() => {
                                          handleActionOnSetting(
                                            BTN_ACTIONS.SAVE,
                                            masterSetting?.name,
                                            null,
                                            masterSetting?.id
                                          );
                                        }}
                                      >
                                        <CheckCircleOutline />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        onClick={() => {
                                          handleActionOnSetting(
                                            BTN_ACTIONS.CANCEL,
                                            masterSetting?.name,
                                            masterSetting?.value
                                          );
                                        }}
                                      >
                                        <CancelOutlined />
                                      </IconButton>
                                    </Stack>
                                  )}
                                </>
                              )}
                            </Stack>
                          ))}
                          {/* </FormProvider> */}
                        </form>
                      </Stack>
                    </>
                  )}
                </Paper>
              ))
            }
          </MDBox>
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default MasterSettings;
