// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
// import { ModalContext } from "context/ModalCtx";

import { getServices, deleteService } from "lib/Api/Services";

import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { LoginFormContext } from "context/loginFormCtx";
import { STATUS_BUTTON_VALUE, DURATION_UNIT } from "utils/constants";
import { Delete, Edit } from "@mui/icons-material";

import moment from "moment";
import { updateService } from "lib/Api/Services";
import { getNamesOfServiceTypes } from "lib/Api/ServiceTypes";
import { TABLE_FILTERS } from "utils/constants";

import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";

function Services() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const [serviceTypesList, setServiceTypesList] = useState([]);

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteService(id);

        if (response?.status === 200) {
          getServicesData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateService(id, { status: status ? 0 : 1 });

        if (response?.status === 200) {
          getServicesData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Name",
      accessor: "name",
      filterInputType: "text",
      sort: true,
      Cell: ({ value, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Service Type",
      accessor: "service_type_id",
      filterInputType: "select",
      filterOptions: serviceTypesList,
      sort: true,
      Cell: ({ value, row }) => <DefaultCell value={row.original.service_type?.name ?? "N/A"} />,
    },
    {
      Header: "status",
      accessor: "status",
      filterInputType: "select",
      sort: false,
      filterOptions: [
        { label: "Inactive", value: 0 },
        { label: "Active", value: 1 },
      ],
      Cell: ({ value, row }) => (
        <MDButton
          color={STATUS_BUTTON_VALUE[value].color}
          sx={{
            background: `${STATUS_BUTTON_VALUE[value].background}`,
          }}
          size="small"
          circular={true}
          onClick={() => {
            handleStatusChange(row.original.id, value);
          }}
        >
          {STATUS_BUTTON_VALUE[value].label}
        </MDButton>
      ),
    },
    {
      Header: "Created At",
      accessor: "created_at",
      filterInputType: "date",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
    },
    {
      Header: "Action",
      width: "3%",
      sort: false,
      Cell: ({ value, row }) => {
        return (
          <MDBox>
            <Tooltip title="Edit Service" placement="top">
              <IconButton
                onClick={() => {
                  navigate(`/services/${row.original.id}/edit`);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Service" placement="top">
              <IconButton
                onClick={() => {
                  handleDelete(row.original.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </MDBox>
        );
      },
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
    count: 0,
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.SERVICES) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.SERVICES) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.SERVICES, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.SERVICES, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.SERVICES, sortObject);
  };

  const getServicesData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status" || filter.id == "service_type_id") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getServices(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
          count: response.data.data.count,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getServicesData): ", error);
    }
  };

  const getAllServiceTypesList = async () => {
    try {
      setIsLoading(true);
      const response = await getNamesOfServiceTypes();

      if (response && response.status === 200) {
        const temp = response.data.data.rows.map((serviceType) => {
          return {
            label: serviceType.label,
            value: +serviceType.id,
          };
        });
        const copiedDataTableColumns = [...dataTableColumns];

        copiedDataTableColumns.map((column) => {
          if (column.accessor == "service_type_id") {
            column.filterOptions = [...temp];
          }
          return column;
        });

        setDataTableColumns(copiedDataTableColumns);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error (getAllServiceTypesList): ", error);
    }
  };

  useEffect(() => {
    getAllServiceTypesList();
  }, []);

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getServicesData();
  }, [page, pageSize, filters, sortBy]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Services
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the services.
                </MDTypography>
              </Grid>
              <Grid item>
                <Link to="/services/add-service">
                  <Button variant="outlined" startIcon={<AddIcon />}>
                    Add New Service
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </MDBox>
          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getServicesData}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default Services;
