import { Card, Grid, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { getPlanById } from "lib/Api/Plans";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListItem, ListItemText } from "@mui/material";
import { PLAN_TYPES } from "utils/constants";
import { PLAN_DURATION_TYPE } from "utils/constants";
import moment from "moment";
// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { IconButton } from "@mui/material";
import DefaultCell from "examples/Tables/components/DefaultCell";
import LocalDataTable from "examples/Tables/LocalDataTable";
import { STATUS_BUTTON_VALUE } from "utils/constants";

function PlanDetails() {
  const dataTableData = {
    columns: [
      {
        Header: "Subscription Id",
        accessor: "subscription_id",
        filterInputType: "text",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Subscriber",
        accessor: "user",
        filterInputType: "text",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Purchase Price",
        accessor: "purchase_price",
        filterInputType: "number",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Subscribed On",
        accessor: "purchase_date",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Subscription Ended On",
        accessor: "end_date",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        filterInputType: "select",
        filterOptions: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
        ],
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [],
  };

  const [tableData, setTableData] = useState({
    columns: dataTableData.columns,
    rows: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const [planData, setPlanData] = useState([]);
  //   console.log("planData:101 ", planData);

  const { id } = useParams();

  const getPlanDetails = async (tenantId) => {
    try {
      const response = await getPlanById(tenantId);
      if (response?.status === 200) {
        setPlanData(response?.data?.data);

        const tempRows = response?.data?.data?.subscriptions?.map((data) => {
          return {
            subscription_id: data.subscription_id,
            user: `${data.user.first_name} ${data.user.last_name}`,
            purchase_price: data.purchase_price,
            purchase_date: moment(data.purchase_date).format("MM/DD/YYYY"),
            end_date: moment(data.end_date).format("MM/DD/YYYY"),
            status: STATUS_BUTTON_VALUE[data.status].label,
          };
        });

        setTableData({
          ...tableData,
          rows: tempRows,
        });
      }
    } catch (error) {
      console.log("Error (getTenantDetails): ", error);
    }
  };

  useEffect(() => {
    getPlanDetails(id);
  }, [id]);

  return (
    <CustomPageLayout>
      <Card>
        <Grid
          container
          spacing={2}
          sx={{
            p: 3,
          }}
        >
          <Grid item xs={12} md={12}>
            <MDTypography variant="h4" color="primary">
              {planData?.name}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Plan Type"
                secondary={PLAN_TYPES[planData?.plan_type ? planData?.plan_type : 'N/A']}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Plan Duration Type"
                secondary={PLAN_DURATION_TYPE[planData?.plan_duration_type ? planData?.plan_duration_type : 'N/A']}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Duration"
                secondary={planData?.duration|| "N/A"}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="User Charges"
                secondary={planData?.per_user_charges|| "N/A"}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Discount"
                secondary={planData?.discount|| "N/A"}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          {/* <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Final Price"
                secondary={planData?.final_price|| "N/A"}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid> */}
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Created At"
                secondary={moment(planData?.created_at ? planData?.created_at  : 'N/A').format("MM/DD/YYYY")}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={2} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Updated At"
                secondary={moment(planData?.updated_at ? planData?.updated_at  : 'N/A').format("MM/DD/YYYY")}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 1 }}>
            <ListItem>
              <ListItemText
                primary="Description"
                secondary={planData?.description|| "N/A"}
                primaryTypographyProps={{ variant: "h6", color: "black" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "text.secondary",
                }}
              />
            </ListItem>
          </Grid>
        </Grid>
        <MDTypography sx={{ pl: 3 }} variant="h4" color="primary">
          Subscriber Details
        </MDTypography>
        <Stack>
          <LocalDataTable isLoading={isLoading} table={tableData} isCSVExport />
        </Stack>
      </Card>
    </CustomPageLayout>
  );
}

export default PlanDetails;
