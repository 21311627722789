// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDModal from "components/MDModal";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";
// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid } from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";
import { ModalContext } from "context/ModalCtx";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { getServiceTypes } from "lib/Api/ServiceTypes";
import AddServiceTypeForm from "components/ServiceTypes/AddServiceTypeForm";

import { Add, Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DefaultCell from "examples/Tables/components/DefaultCell";
import moment from "moment";
import { STATUS_BUTTON_VALUE } from "utils/constants";
import { LoginFormContext } from "context/loginFormCtx";
import { deleteServiceTypes } from "lib/Api/ServiceTypes";
import { SERVICE_TYPES_ACTIONS } from "utils/constants";
import EditServiceTypeForm from "components/ServiceTypes/EditServiceTypeForm";
import { updateServiceTypes } from "lib/Api/ServiceTypes";
import { TABLE_FILTERS } from "utils/constants";
import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";

function ServiceType() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditForm, setOpenEditForm] = useState(false);

  const serviceTypeId = useRef();

  const dataTableData = {
    columns: [
      {
        Header: "Id",
        accessor: "id",
        filterInputType: "number",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={`# ${value}`} />,
      },
      {
        Header: "Name",
        accessor: "name",
        filterInputType: "text",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "description",
        filterInputType: "text",
        sort: true,
        Cell: ({ value }) => <div style={{ maxWidth: "230px" }}>{value}</div>,
      },
      {
        Header: "status",
        accessor: "status",
        filterInputType: "select",
        sort: true,
        filterOptions: [
          { label: "Inactive", value: 2 },
          { label: "Active", value: 1 },
        ],
        Cell: ({ value, row }) => {
          const tempValue = value;

          const statusToggleBody = {
            id: row.values.id,
            status: tempValue == 1 ? 2 : 1,
          };
          return (
            <MDButton
              color={STATUS_BUTTON_VALUE[tempValue == 2 ? 0 : value].color}
              sx={{
                background: `${STATUS_BUTTON_VALUE[tempValue == 2 ? 0 : value].background}`,
              }}
              size="small"
              circular={true}
              onClick={() => {
                handleStatusToggle(statusToggleBody);
              }}
            >
              {STATUS_BUTTON_VALUE[tempValue == 2 ? 0 : value].label}
            </MDButton>
          );
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterInputType: "date",
        sort: true,
        disableFuture: true,
        Cell: ({ value, row }) => {
          return moment(value).format("MM/DD/YYYY");
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        filterInputType: "date",
        sort: true,
        disableFuture: true,
        Cell: ({ value, row }) => {
          return moment(value).format("MM/DD/YYYY");
        },
      },
      {
        Header: "Actions",
        sort: false,
        Cell: ({ value, row, column }) => {
          return (
            <MDBox>
              <IconButton
                onClick={() => {
                  handleEdit(row.values.id);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleDelete(row.values.id);
                }}
              >
                <Delete />
              </IconButton>
            </MDBox>
          );
        },
      },
    ],

    rows: [],
  };

  const [tableData, setTableData] = useState({
    columns: dataTableData.columns,
    rows: [],
    count: 0,
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.SERVICE_TYPES) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.SERVICE_TYPES) || []);
  const [openAddForm, setOpenAddForm] = useState(false);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.SERVICE_TYPES, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.SERVICE_TYPES, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.SERVICE_TYPES, sortObject);
  };

  const getServiceTypeData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getServiceTypes(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
          count: response.data.data.count,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this service type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteBody = {
          id: id,
          action: SERVICE_TYPES_ACTIONS.DELETE,
        };
        const response = await deleteServiceTypes(deleteBody);

        if (response?.status === 200) {
          getServiceTypeData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusToggle = async (body) => {
    try {
      showMessageNotification({
        title: "Are you sure?",
        text: "You want to change the status of this service type?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--cc-confirm-button-color)",
        cancelButtonColor: "var(--cc-cancel-button-color)",
        confirmButtonText: "Yes, change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await updateServiceTypes(body);

          if (response?.status === 200) {
            getServiceTypeData();
            setNotification({
              color: "success",
              icon: "check",
              title: "Success",
              content: response.data.message,
              open: true,
            });
          } else {
            setNotification({
              color: "error",
              icon: "check",
              title: "error",
              content: response.data.message,
              open: true,
            });
          }
        }
      });
    } catch (error) {
      setNotification({
        color: "error",
        icon: "check",
        title: "error",
        content: "Something went wrong while processing your request. Please try again later.",
        open: true,
      });
    }
  };

  const handleEdit = (id) => {
    serviceTypeId.current = id;
    setOpenEditForm(true);
  };

  const handleLoad = () => {
    setIsLoading(true);

    if (tableData.rows.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServiceTypeData();
  }, [page, pageSize, filters, sortBy]);

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Service Types
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the service types.
                </MDTypography>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<Add />} onClick={() => setOpenAddForm(true)}>
                  Add Service Types
                </Button>
              </Grid>
            </Grid>
          </MDBox>

          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getServiceTypeData}
              handleClearAllFilters={handleClearAllFilters}
            />
          )}
        </Card>
      </MDBox>

      <MDModal
        openState={openAddForm}
        handleFormClose={() => {
          setOpenAddForm(false);
        }}
        modalhead="Add Service Type"
        width="40%"
        defaultPosition={{ x: 275, y: -400 }}
      >
        <AddServiceTypeForm
          setOpenAddForm={setOpenAddForm}
          getServiceTypeData={getServiceTypeData}
        />
      </MDModal>

      <MDModal
        openState={openEditForm}
        handleFormClose={() => {
          setOpenEditForm(false);
        }}
        modalhead="Edit Service Type"
        width="40%"
        defaultPosition={{ x: 275, y: -400 }}
      >
        <EditServiceTypeForm
          serviceTypeId={serviceTypeId.current}
          setOpenEditForm={setOpenEditForm}
          getServiceTypeData={getServiceTypeData}
        />
      </MDModal>
    </CustomPageLayout>
  );
}

export default ServiceType;
