import React from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Data
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { Link, redirect, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "rsuite";
import { addPlan } from "lib/Api/Plans";
import { LoginFormContext } from "context/loginFormCtx";
import { VALIDATION_MESSAGES } from "utils/constants";
import { updatePlan } from "lib/Api/Plans";
import { PLAN_TYPE_OPTIONS } from "utils/constants";
import { PLAN_DURATION_TYPE_OPTIONS } from "utils/constants";
import { getAllPlanItems } from "lib/Api/Plans";
import { getFormList } from "lib/Api/FormBuilder";
import { plan_list } from "utils/constants";
import { PLAN_DURATION_TYPE } from "utils/constants";
import { COUPON_TYPE_OPTIONS } from "utils/constants";
import { COUPON_TYPES } from "utils/constants";
import { updateCoupon } from "lib/Api/Coupons";
import { addCoupon } from "lib/Api/Coupons";
import { COUPON_APPLICABLE_FOR_OPTIONS } from "utils/constants";
import { COUPON_APPLICABLE_FOR_TYPES } from "utils/constants";
import { getPlans } from "lib/Api/Plans";
import { CheckBox } from "@mui/icons-material";
import { getTenants } from "lib/Api/Tenants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import moment from "moment";
import dayjs from "dayjs";

function AddEditCouponForm({ couponData }) {
  console.log("couponData?.coupon_applicable_to: 101", couponData?.coupon_applicable_to);

  const { setNotification } = useContext(LoginFormContext);

  const [planItemOptions, setPlanItemOptions] = useState([]);
  const [tenantListOptions, setTenantListOptions] = useState([]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      coupon_name: couponData?.coupon_name || "",
      coupon_type: couponData?.coupon_type
        ? { value: couponData?.coupon_type, label: COUPON_TYPES[couponData?.coupon_type] }
        : null,
      coupon_applicable_from: couponData?.coupon_applicable_from
        ? dayjs(couponData.coupon_applicable_from)
        : null,
      coupon_applicable_to: couponData?.coupon_applicable_to
        ? dayjs(couponData.coupon_applicable_to)
        : null,
      coupon_applicable_for: couponData?.coupon_applicable_for
        ? {
            value: couponData?.coupon_applicable_for,
            label: COUPON_APPLICABLE_FOR_TYPES[couponData?.coupon_applicable_for],
          }
        : {
            value: 1,
            label: "Both",
          },
      plan_list: couponData?.plan_list
        ? planItemOptions.filter((option) => couponData.plan_list.includes(option.value))
        : null,
      tenant_list: couponData?.tenant_list
        ? tenantListOptions.filter((option) => couponData.tenant_list.includes(option.value))
        : null,
      is_applicable_for_tenant: couponData?.is_applicable_for_tenant ?? true,
      discount: couponData?.discount || null,
      coupon_usage_limit: couponData?.coupon_usage_limit || null,
      is_applicable_for_subscription: couponData?.is_applicable_for_subscription ?? false,

      // coupon_name: "",
      // coupon_type: null,
      // coupon_applicable_from: "",
      // coupon_applicable_to: "",
      // coupon_applicable_for: null,
      // plan_list:  null,
      // tenant_list:  null,
      // is_applicable_for_tenant: true,
      // discount:null,
      // coupon_usage_limit : null,
      // is_applicable_for_subscription :false
    },
    validationSchema: Yup.object().shape({
      coupon_name: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, "Only alphanumeric characters are allowed (no spaces)")
        .max(20, "Max 20 alphanumeric characters are allowed")
        .required("Coupon Name is required"),
      coupon_type: Yup.object().required("Coupon Type is required"),
      coupon_applicable_from: Yup.date().nullable(),
      coupon_applicable_to: Yup.date()
        .nullable()
        .test("is-after-start-date", "End date must be after start date", function (value) {
          const { coupon_applicable_from } = this.parent;
          return !coupon_applicable_from || !value || value > coupon_applicable_from;
        }),
      coupon_applicable_for: Yup.object().required("Coupon Applicable for is required"),
      coupon_usage_limit:Yup.number().nullable().optional().min(1,'The usage limit must be set at 1 or higher'),
      plan_list: Yup.array()
        .nullable()
        .optional()
        .when("coupon_applicable_for", {
          is: (datevalue) => datevalue?.value === 2,
          then: () =>
            Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string().required(),
                  label: Yup.string().required(),
                })
              )
              .min(1, "Please select at least one plan type")
              .required("Plan type is required"),
        }),
      tenant_list: Yup.array()
        .nullable()
        .optional()
        .when("is_applicable_for_tenant", {
          is: false,
          then: () =>
            Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string().required(),
                  label: Yup.string().required(),
                })
              )
              .min(1, "Please select at least one tenant")
              .required("Tenant type is required"),
        }),
      is_applicable_for_tenant: Yup.boolean().optional(),
      discount: Yup.number().when("coupon_type", {
        is: (value) => value?.value === 1,
        then: () =>
          Yup.number().integer("Discount must be an integer").required("Discount is required"),
        otherwise: () => Yup.number().required("Discount is required"),
      }),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log("values: 101", values.is_applicable_for_tenant);
      try {
        setSubmitting(true);

        const temp = {
          ...values,
          coupon_type: values.coupon_type.value,
          coupon_applicable_for: values.coupon_applicable_for.value,
          plan_list: values?.plan_list?.map((item) => item.value) ?? null,
          tenant_list: values?.tenant_list?.map((item) => item.value) ?? null,
          is_applicable_for_subscription: values.coupon_applicable_for.value === 2 ? true : false,
          coupon_applicable_from: values.coupon_applicable_from
            ? dayjs(values.coupon_applicable_from).format("YYYY-MM-DD")
            : null,
          coupon_applicable_to: values.coupon_applicable_to
            ? dayjs(values.coupon_applicable_to).format("YYYY-MM-DD")
            : null,
        };

        // console.log("values: ", temp);

        let response;
        if (couponData) {
          response = await updateCoupon(couponData.id, temp);
        } else {
          response = await addCoupon(temp);
        }

        if (response && response.status === 200) {
          setSubmitting(false);
          resetForm();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
          navigate("/coupons");
        } else {
          setSubmitting(false);
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response.data.message,
            open: true,
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error.message || "Something went wrong!",
          open: true,
        });
      }
    },
  });

  const planItemsOptions = async () => {
    const response = await getPlans();
    if (response?.status === 200) {
      const temp = response?.data?.data?.rows.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setPlanItemOptions(temp);
    }
  };

  const tenantItemsOptions = async () => {
    try {
      const response = await getTenants();
      if (response?.status === 200) {
        const options = response.data.data.tenants.map((item) => ({
          value: item.id,
          label: item.company_name,
        }));
        setTenantListOptions(options);
      }
    } catch (error) {
      console.error("Error fetching tenant options:", error);
    }
  };

  useEffect(() => {
    tenantItemsOptions();
    planItemsOptions();
  }, []);

  useEffect(() => {
    // Ensure Formik values are set after tenantListOptions is populated
    if (tenantListOptions.length > 0) {
      formik.setFieldValue(
        "tenant_list",
        couponData?.tenant_list
          ? tenantListOptions.filter((option) => couponData.tenant_list.includes(option.value))
          : []
      );
    }

    // Ensure Formik values are set after planItemOptions is populated
    if (planItemOptions.length > 0) {
      formik.setFieldValue(
        "plan_list",
        couponData?.plan_list
          ? planItemOptions.filter((option) => couponData.plan_list.includes(option.value))
          : []
      );
    }
  }, [tenantListOptions, planItemOptions]);

  const applicableType = formik.values.coupon_applicable_for?.value;

  const checkBoxvalue = formik.values.is_applicable_for_tenant;

  console.log("formik.errors: 101", formik.errors);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="is_applicable_for_tenant"
                checked={formik.values.is_applicable_for_tenant}
                onChange={formik.handleChange}
              />
            }
            sx={{ width: "220px" }}
            label="Applicable to all the tenant"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextField
            type="text"
            value={formik.values.coupon_name}
            error={Boolean(formik.touched.coupon_name && formik.errors.coupon_name)}
            helperText={formik.touched.coupon_name && formik.errors.coupon_name}
            fullWidth
            label="Coupon Name"
            name="coupon_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Autocomplete
            value={formik.values.coupon_type}
            size="medium"
            options={COUPON_TYPE_OPTIONS}
            onChange={(event, newValue) => {
              formik.setFieldValue("coupon_type", newValue);
            }}
            placeholder="Select Coupon Type"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Coupon Type"
                required
                error={Boolean(formik.touched.coupon_type && formik.errors.coupon_type)}
                helperText={formik.touched.coupon_type && formik.errors.coupon_type}
              />
            )}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField
            type="number"
            value={formik.values.discount}
            error={Boolean(formik.touched.discount && formik.errors.discount)}
            helperText={formik.touched.discount && formik.errors.discount}
            fullWidth
            label="Discount"
            name="discount"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>

        {checkBoxvalue === false && (
          <Grid item md={4} xs={12}>
            <Autocomplete
              multiple
              limitTags={2}
              value={formik.values.tenant_list || []}
              size="medium"
              fullWidth
              options={tenantListOptions}
              onChange={(event, newValue) => {
                formik.setFieldValue("tenant_list", newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tenant"
                  error={Boolean(formik.touched.tenant_list && formik.errors.tenant_list)}
                  helperText={formik.touched.tenant_list && formik.errors.tenant_list}
                />
              )}
              required
            />
          </Grid>
        )}

        {checkBoxvalue === true && (
          <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.coupon_usage_limit}
              error={Boolean(formik.touched.coupon_usage_limit && formik.errors.coupon_usage_limit)}
              helperText={formik.touched.coupon_usage_limit && formik.errors.coupon_usage_limit}
              fullWidth
              label="Coupon Usage Limit"
              name="coupon_usage_limit"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        )}

        <Grid item md={4} xs={12}>
          <Autocomplete
            value={formik.values.coupon_applicable_for}
            size="medium"
            options={COUPON_APPLICABLE_FOR_OPTIONS}
            onChange={(event, newValue) => {
              formik.setFieldValue("coupon_applicable_for", newValue);
            }}
            placeholder="Select Coupon Applicable Type"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Coupon Applicable For"
                error={Boolean(
                  formik.touched.coupon_applicable_for && formik.errors.coupon_applicable_for
                )}
                helperText={
                  formik.touched.coupon_applicable_for && formik.errors.coupon_applicable_for
                }
              />
            )}
            required
          />
        </Grid>

        {applicableType === 2 && (
          <Grid item md={4} xs={12}>
            <Autocomplete
              value={formik.values.plan_list || []}
              multiple
              limitTags={2}
              size="medium"
              options={planItemOptions}
              onChange={(event, newValue) => {
                formik.setFieldValue("plan_list", newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plan"
                  error={Boolean(formik.touched.plan_list && formik.errors.plan_list)}
                  helperText={formik.touched.plan_list && formik.errors.plan_list}
                />
              )}
              required
            />
          </Grid>
        )}

        <Grid item md={4} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Coupon Start Date"
              value={formik.values.coupon_applicable_from}
              onChange={(date) => formik.setFieldValue("coupon_applicable_from", date)}
              slotProps={{
                textField: {
                  name: "coupon_applicable_from",
                  error:
                    formik.touched.coupon_applicable_from &&
                    Boolean(formik.errors.coupon_applicable_from),
                  helperText:
                    formik.touched.coupon_applicable_from && formik.errors.coupon_applicable_from,
                },
              }}
              disablePast
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={4} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Coupon End Date"
              value={formik.values.coupon_applicable_to}
              onChange={(date) => formik.setFieldValue("coupon_applicable_to", date)}
              slotProps={{
                textField: {
                  name: "coupon_applicable_to",
                  error:
                    formik.touched.coupon_applicable_to &&
                    Boolean(formik.errors.coupon_applicable_to),
                },
              }}
              disablePast
            />
          </LocalizationProvider>
          {formik.touched.coupon_applicable_to && formik.errors.coupon_applicable_to && (
            <FormHelperText error>{formik.errors.coupon_applicable_to}</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Divider />
      <MDBox sx={{ mt: 2 }} display="flex" justifyContent="center" alignItems="center">
        <Button disabled={formik.isSubmitting} type="submit" sx={{ m: 1 }} variant="contained">
          {couponData ? "Edit Coupon" : "Add Coupon"}
        </Button>

        <Link to="/coupons">
          <Button variant="outlined">Cancel</Button>
        </Link>
      </MDBox>
    </form>
  );
}

export default AddEditCouponForm;
