// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
// import { ModalContext } from "context/ModalCtx";

import { getRolePermissions } from "lib/Api/Roles";

import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { LoginFormContext } from "context/loginFormCtx";
import { STATUS_BUTTON_VALUE } from "utils/constants";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { deleteRolePermissions, updateRoleStatus } from "lib/Api/Roles";
import moment from "moment";
import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { TABLE_FILTERS } from "utils/constants";

function Roles() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRolePermissions(id);

        if (response?.status === 200) {
          getRolesData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateRoleStatus(id, { status: status ? 0 : 1 });

        if (response?.status === 200) {
          getRolesData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Id",
        accessor: "id",
        filterInputType: "number",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={`# ${value}`} />,
      },
      {
        Header: "Name",
        accessor: "name",
        filterInputType: "text",
        sort: true,
        Cell: ({ value, row }) => {
          return (
            <>
              <Link to={`/roles/${row.values.id}`}>
                <DefaultCell value={value} />
              </Link>
            </>
          );
        },
      },
      {
        Header: "status",
        accessor: "status",
        filterInputType: "select",
        sort: true,
        filterOptions: [
          { label: "Inactive", value: 0 },
          { label: "Active", value: 1 },
        ],
        Cell: ({ value, row }) => (
          <MDButton
            color={STATUS_BUTTON_VALUE[value].color}
            sx={{
              background: `${STATUS_BUTTON_VALUE[value].background}`,
            }}
            size="small"
            circular={true}
            onClick={() => {
              handleStatusChange(row.original.id, value);
            }}
          >
            {STATUS_BUTTON_VALUE[value].label}
          </MDButton>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterInputType: "date",
        sort: true,
        disableFuture: true,
        Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        filterInputType: "date",
        sort: true,
        disableFuture: true,  
        Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
      },
      {
        Header: "Action",
        width: "3%",
        sort: false,
        Cell: ({ value, row }) => {
          return (
            <MDBox>
              <Tooltip title="View Role" placement="top">
              <IconButton
                onClick={() => {
                  navigate(`/roles/${row.values.id}`, { state: { isEditMode: false } });
                }}
              >
                <Visibility />
              </IconButton>
              </Tooltip>
              <Tooltip title="Edit Role" placement="top">
              <IconButton
                onClick={() => {
                  navigate(`/roles/${row.values.id}`, { state: { isEditMode: true } });
                }}
              >
                <Edit />
              </IconButton>
              </Tooltip>
              <Tooltip title="Delete Role" placement="top">
              <IconButton
                onClick={() => {
                  handleDelete(row.values.id);
                }}
              >
                <Delete />
              </IconButton>
              </Tooltip>
            </MDBox>
          );
        },
      },
    ],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableData.columns,
    rows: [],
    count: 0,
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.ROLES) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.ROLES) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.ROLES, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.ROLES, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.ROLES, sortObject);
  };

  const getRolesData = async () => {
    try {
      //setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getRolePermissions(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.roles,
          count: response.data.data.totalRoles,
        });
        setTotalPages(Math.ceil(response.data.data.totalRoles / pageSize));

         //setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getRolesData): ", error);
    }
  };

  const handleLoad = () => {
    setIsLoading(true);

    if (tableData.rows.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRolesData();
  }, [page, pageSize, filters, sortBy]);

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Roles
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the roles.
                </MDTypography>
              </Grid>
              <Grid item>
                <Link to="/roles/add-role">
                  <Button variant="outlined" startIcon={<AddIcon />} onClick={() => {}}>
                    Add Role
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </MDBox>

          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getRolesData}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default Roles;
